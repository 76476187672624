import { ManageApplicantsActionTypes, ManageApplicantsAction } from './manageApplicantsActionTypes';

export const getApplicantsRequest = (data: any): ManageApplicantsAction => ({
  type: ManageApplicantsActionTypes.GET_APPLICANTS,
  payload: data
});

export const getApplicantsRequestSuccess = (data: any): ManageApplicantsAction => ({
  type: ManageApplicantsActionTypes.GET_APPLICANTS_SUCCESS,
  payload: data
});

export const getApplicantsRequestError = (error: string): ManageApplicantsAction => ({
  type: ManageApplicantsActionTypes.GET_APPLICANTS_ERROR,
  payload: error
});

export const updateApplicantsRequest = (data: any): ManageApplicantsAction => ({
  type: ManageApplicantsActionTypes.UPDATE_APPLICANTS,
  payload: data
});

export const updateApplicantsRequestSuccess = (data: any): ManageApplicantsAction => ({
  type: ManageApplicantsActionTypes.UPDATE_APPLICANTS_SUCCESS,
  payload: data
});

export const updateApplicantsRequestError = (error: string): ManageApplicantsAction => ({
  type: ManageApplicantsActionTypes.UPDATE_APPLICANTS_ERROR,
  payload: error
});

export const getCourseRegistrantsRequest = (data: any): ManageApplicantsAction => ({
  type: ManageApplicantsActionTypes.GET_COURSE_REGISTRANTS,
  payload: data
});

export const getCourseRegistrantsRequestSuccess = (data: any): ManageApplicantsAction => ({
  type: ManageApplicantsActionTypes.GET_COURSE_REGISTRANTS_SUCCESS,
  payload: data
});

export const getCourseRegistrantsRequestError = (error: string): ManageApplicantsAction => ({
  type: ManageApplicantsActionTypes.GET_COURSE_REGISTRANTS_ERROR,
  payload: error
});

export const updateCourseRegistrantsRequest = (data: any): ManageApplicantsAction => ({
  type: ManageApplicantsActionTypes.UPDATE_COURSE_REGISTRANTS,
  payload: data
});

export const updateCourseRegistrantsRequestSuccess = (data: any): ManageApplicantsAction => ({
  type: ManageApplicantsActionTypes.UPDATE_COURSE_REGISTRANTS_SUCCESS,
  payload: data
});

export const updateCourseRegistrantsRequestError = (error: string): ManageApplicantsAction => ({
  type: ManageApplicantsActionTypes.UPDATE_COURSE_REGISTRANTS_ERROR,
  payload: error
});
