import { takeLatest, put, call } from 'redux-saga/effects';
import { HomeworkActionTypes } from '../actions/homeworkActionTypes';
import { requestHomeworkPDFSuccess, requestHomeworkPDFError } from '../actions/homeworkActions';
import { apiService } from '../../../services/api';

function* requestHomeworkPDFSaga(req: any) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getHomeworkPDF, req.payload);

    // Dispatch the success action with the API response
    yield put(requestHomeworkPDFSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(requestHomeworkPDFError(error.message));
  }
}

export function* watchRequestHomeworkPDF() {
  yield takeLatest(HomeworkActionTypes.REQUEST_HOMEWORK_PDF, requestHomeworkPDFSaga);
}
