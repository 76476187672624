// Import statements
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Message, VideoMessage } from '../types';

// WebSocketService class
class WebSocketService {
  private socket: WebSocket | null = null;
  private onOpenCallback: ((event: Event) => void) | null = null;
  private chatroomId: string | null = null;
  private onChatroomIdReceived: ((chatroomId: string) => void) | null = null;
  private pendingConnection: Promise<void> | null = null;
  onCloseCallback: () => void;

  // Add getter for chatroomId
  createRoom() {
    return this.chatroomId;
  }

  // Add method to register chatroomId callback
  onChatroomId(callback: (chatroomId: string) => void) {
    this.onChatroomIdReceived = callback;
  }

  constructor() {
    this.setupBeforeUnloadListener();
  }

  private setupBeforeUnloadListener() {
    window.addEventListener('beforeunload', () => {
      this.disconnect();
    });
  }

  async connect({
    url,
    username,
    receiver,
    user_id
  }: {
    url: string;
    username?: string;
    receiver?: string;
    user_id?: string;
  }) {
    console.log('Connecting to WebSocket server', url);
    this.disconnect();

    if (username && receiver) {
      const sortedUsers = [username, receiver].sort();
      this.chatroomId = `${sortedUsers[0]}_${sortedUsers[1]}`;
    }

    // Create a promise that resolves when the connection is established
    this.pendingConnection = new Promise((resolve, reject) => {
      let wsUrl = `wss://${url}`;
      if (username || receiver) {
        wsUrl += '?';
        const params = [];
        if (username) params.push(`username=${encodeURIComponent(username)}`);
        if (receiver) params.push(`receiver=${encodeURIComponent(receiver)}`);
        if (user_id) params.push(`user_id=${encodeURIComponent(user_id)}`);
        if (this.chatroomId) params.push(`chatroomId=${encodeURIComponent(this.chatroomId)}`);
        wsUrl += params.join('&');
      }

      this.socket = new WebSocket(wsUrl);
      console.log('WebSocket socket created with URL:', wsUrl);

      this.socket.onopen = (event) => {
        console.log('WebSocket connection opened');
        console.log('onopen event', event);
        if (this.onOpenCallback) {
          this.onOpenCallback(event);
        }

        // Send initial message after connection is established
        this.sendInitialMessage(username, receiver);
        resolve();
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket connection error:', error);
        reject(error);
      };

      this.socket.onmessage = (event) => {
        console.log('Received WebSocket message:', event.data);
        const data = JSON.parse(event.data);

        if (data.chatroomId) {
          console.log('Received chatroomId:', data.chatroomId);
          this.chatroomId = data.chatroomId;

          if (this.onChatroomIdReceived) {
            this.onChatroomIdReceived(data.chatroomId);
          }

          this.setupMessageHandler();
        } else if (this.messageCallback) {
          this.messageCallback(data);
        }
      };
    });

    // Wait for connection to be established
    await this.pendingConnection;
  }

  private async sendInitialMessage(username?: string, receiver?: string) {
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(
        JSON.stringify({
          action: 'createRoom',
          chatroomId: this.chatroomId,
          username,
          receiver
        })
      );
    }
  }

  async sendMessage({
    message,
    username,
    receiver,
    user_id
  }: {
    message: Message;
    username: string;
    receiver: string;
    user_id: string;
  }) {
    // If not connected or connecting, establish connection first
    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      const connection = await this.connect({
        url: localStorage.getItem('webSocketUrl')!,
        username,
        receiver,
        user_id
      });
      console.log('connection', connection);
      return connection;
    }

    // If there's a pending connection, wait for it
    if (this.pendingConnection) {
      await this.pendingConnection;
    }

    // Ensure the socket is open before sending
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(
        JSON.stringify({
          action: 'sendMessage',
          connectionId: username,
          message_type: 'individual',
          receiver,
          reactions: [],
          owner: username,
          createdAt: new Date().toISOString(),
          message: JSON.stringify(message),
          chatroomId: this.chatroomId
        })
      );
    } else {
      throw new Error('WebSocket is not in OPEN state');
    }
  }

  private messageCallback: ((message: any) => void) | null = null;

  sendVideoMessage({
    videoMessage,
    username,
    receiver,
    user_id
  }: {
    videoMessage: VideoMessage;
    username: string;
    receiver: string;
    user_id: string;
  }) {
    if (this.socket?.readyState !== this.socket?.OPEN) {
      this.connect({
        url: localStorage.getItem('webSocketUrl')!,
        username,
        receiver,
        user_id
      });
      console.log('WebSocket is connected');
    } else {
      console.log('WebSocket is already connected');
    }
    this.socket?.send(JSON.stringify(videoMessage));
  }

  onOpen(callback: (event: Event) => void) {
    this.onOpenCallback = callback;
  }

  onClose(callback: () => void) {
    this.onCloseCallback = callback;
  }

  onMessage(callback: (message: any) => void) {
    this.messageCallback = callback;
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.setupMessageHandler();
    }
  }

  private setupMessageHandler() {
    if (this.socket) {
      this.socket.onmessage = (event) => {
        if (this.messageCallback) {
          this.messageCallback(JSON.parse(event.data));
        }
      };
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }
}

// Create a context
const WebSocketContext = createContext<WebSocketService | null>(null);

// Create a provider component
export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [webSocketService] = useState(new WebSocketService());

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      webSocketService.disconnect();
    };
  }, [webSocketService]);

  return <WebSocketContext.Provider value={webSocketService}>{children}</WebSocketContext.Provider>;
};

// Custom hook to use the WebSocket context
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

// Export the WebSocketService class, context, and hooks for use in other parts of the project
export { WebSocketService, WebSocketContext };
