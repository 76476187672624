export enum createHomeworkActionTypes {
  CREATE_HOMEWORK = 'createHomework/CREATE_HOMEWORK',
  CREATE_HOMEWORK_SUCCESS = 'createHomework/CREATE_HOMEWORK_SUCCESS',
  CREATE_HOMEWORK_ERROR = 'createHomework/CREATE_HOMEWORK_ERROR',
  ASSIGN_HOMEWORK_TO_STUDENT = 'createHomework/ASSIGN_HOMEWORK_TO_STUDENT',
  ASSIGN_HOMEWORK_TO_STUDENT_SUCCESS = 'createHomework/ASSIGN_HOMEWORK_TO_STUDENT_SUCCESS',
  ASSIGN_HOMEWORK_TO_STUDENT_ERROR = 'createHomework/ASSIGN_HOMEWORK_TO_STUDENT_ERROR',
  GET_SAMPLE_HOMEWORKS = 'createHomework/GET_SAMPLE_HOMEWORKS',
  GET_SAMPLE_HOMEWORKS_SUCCESS = 'createHomework/GET_SAMPLE_HOMEWORKS_SUCCESS',
  GET_SAMPLE_HOMEWORKS_ERROR = 'createHomework/GET_SAMPLE_HOMEWORKS_ERROR',
  GET_HOMEWORK_CATEGORIES = 'createHomework/GET_HOMEWORK_CATEGORIES',
  GET_HOMEWORK_CATEGORIES_SUCCESS = 'createHomework/GET_HOMEWORK_CATEGORIES_SUCCESS',
  GET_HOMEWORK_CATEGORIES_ERROR = 'createHomework/GET_HOMEWORK_CATEGORIES_ERROR'
}

export interface createHomeworkAction {
  type: createHomeworkActionTypes;
  payload?: any;
}
