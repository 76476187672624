import { UpdateProfileActionTypes, UpdateProfileAction } from './updateProfileActionTypes';

export const updateProfileRequest = (data: any): UpdateProfileAction => ({
  type: UpdateProfileActionTypes.UPDATE_PROFILE_REQUEST,
  payload: data
});

export const updateProfileRequestSuccess = (data: any): UpdateProfileAction => ({
  type: UpdateProfileActionTypes.UPDATE_PROFILE_REQUEST_SUCCESS,
  payload: data
});

export const updateProfileRequestError = (error: string): UpdateProfileAction => ({
  type: UpdateProfileActionTypes.UPDATE_PROFILE_REQUEST_ERROR,
  payload: error
});

export const updateOtherProfileRequest = (data: any): UpdateProfileAction => ({
  type: UpdateProfileActionTypes.UPDATE_OTHER_PROFILE_REQUEST,
  payload: data
});

export const updateOtherProfileRequestSuccess = (data: any): UpdateProfileAction => ({
  type: UpdateProfileActionTypes.UPDATE_OTHER_PROFILE_REQUEST_SUCCESS,
  payload: data
});

export const updateOtherProfileRequestError = (error: string): UpdateProfileAction => ({
  type: UpdateProfileActionTypes.UPDATE_OTHER_PROFILE_REQUEST_ERROR,
  payload: error
});
