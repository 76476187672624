import { HomeworkActionTypes } from '../actions/homeworkActionTypes';

interface HomeworkState {
  selectedHomework: any;
  requesting: boolean;
  pdf_response: any;
  request_error: string | null;
  wsHomeworkList: any[];
  loading: boolean;
  error: string | null;
  connectionId: string | null;
  connectionIdError: string | null;
  connectionIdLoading: boolean;
  pageQuestions: any[];
  pageQuestionsError: string | null;
  pageQuestionsLoading: boolean;
  pageDetails: any;
  pageDetailsError: string | null;
  pageDetailsLoading: boolean;
  singleAnswerResponse: any;
  singleAnswerResponseError: string | null;
  singleAnswerResponseLoading: boolean;
}

const initialState: HomeworkState = {
  selectedHomework: null,
  requesting: false,
  pdf_response: null,
  request_error: null,
  wsHomeworkList: [],
  loading: false,
  error: null,
  connectionId: null,
  connectionIdError: null,
  connectionIdLoading: false,
  pageQuestions: [],
  pageQuestionsError: null,
  pageQuestionsLoading: false,
  pageDetails: null,
  pageDetailsError: null,
  pageDetailsLoading: false,
  singleAnswerResponse: null,
  singleAnswerResponseError: null,
  singleAnswerResponseLoading: false
};

const selectedHomeworkReducer = (state = initialState, action) => {
  switch (action.type) {
    case HomeworkActionTypes.SET_HOMEWORK_LIST:
      return {
        ...state,
        wsHomeworkList: action.payload,
        loading: false
      };
    case HomeworkActionTypes.SET_HOMEWORK_LIST_SUCCESS:
      return {
        ...state,
        wsHomeworkList: action.payload,
        loading: false
      };
    case HomeworkActionTypes.SET_HOMEWORK_LIST_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case HomeworkActionTypes.SELECT_HOMEWORK:
      return {
        ...state,
        selectedHomework: action.payload
      };
    case HomeworkActionTypes.REQUEST_HOMEWORK_PDF:
      return {
        ...state,
        requesting: true
      };
    case HomeworkActionTypes.REQUEST_HOMEWORK_PDF_SUCCESS:
      return {
        ...state,
        requesting: false,
        pdf_response: action.payload
      };
    case HomeworkActionTypes.REQUEST_HOMEWORK_PDF_ERROR:
      return {
        ...state,
        requesting: false,
        request_error: action.payload
      };
    case HomeworkActionTypes.SET_CONNECTION_ID:
      console.log('SET_CONNECTION_ID', action.payload);
      return {
        ...state,
        connectionIdLoading: false,
        connectionId: action.payload
      };
    case HomeworkActionTypes.SET_CONNECTION_ID_SUCCESS:
      console.log('SET_CONNECTION_ID_SUCCESS', action.payload);
      return {
        ...state,
        connectionId: action.payload,
        connectionIdLoading: false
      };
    case HomeworkActionTypes.SET_CONNECTION_ID_ERROR:
      return {
        ...state,
        connectionIdError: action.payload,
        connectionIdLoading: false
      };
    case HomeworkActionTypes.SET_PAGE_QUESTIONS:
      return {
        ...state,
        pageQuestions: action.payload
      };
    case HomeworkActionTypes.SET_PAGE_QUESTIONS_SUCCESS:
      return {
        ...state,
        pageQuestions: action.payload
      };
    case HomeworkActionTypes.SET_PAGE_QUESTIONS_ERROR:
      return {
        ...state,
        pageQuestionsError: action.payload,
        pageQuestionsLoading: false
      };
    case HomeworkActionTypes.SET_PAGE_DETAILS:
      return {
        ...state,
        pageDetails: action.payload
      };
    case HomeworkActionTypes.SET_PAGE_DETAILS_SUCCESS:
      return {
        ...state,
        pageDetails: action.payload
      };
    case HomeworkActionTypes.SET_PAGE_DETAILS_ERROR:
      return {
        ...state,
        pageDetailsError: action.payload,
        pageDetailsLoading: false
      };
    case HomeworkActionTypes.UPDATE_HOMEWORK_QUESTION:
      return {
        ...state,
        singleAnswerResponse: action.payload
      };
    case HomeworkActionTypes.UPDATE_HOMEWORK_QUESTION_SUCCESS:
      return {
        ...state,
        singleAnswerResponse: action.payload
      };
    case HomeworkActionTypes.UPDATE_HOMEWORK_QUESTION_ERROR:
      return {
        ...state,
        singleAnswerResponseError: action.payload
      };
    default:
      return state;
  }
};

export default selectedHomeworkReducer;
