// features/login/sagas/loginSaga.ts
import { takeLatest, put, call, delay } from 'redux-saga/effects';
import { LoginActionTypes } from '../actions/LoginActionTypes';
import { loginUserSuccess, loginUserError, loginAccountSuccess, loginAccountError } from '../actions/loginActions';
import { logoutThunk } from '../../otherReducers/Gen3LearningActions';
import { apiService } from '../../../services/api'; // Update the path as needed
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { enqueueSnackbar } from 'notistack';
import * as AWS from 'aws-sdk';
import { useWebSocket } from '../../../Pages/Messenger/context/webSocketContext';

function* loginUserSaga(action) {
  try {
    const { useremail, password } = action.payload;
    // Simulate an asynchronous API call
    const response = yield call(apiService.login, { useremail, password });
    yield put(loginAccountSuccess(response));
    const identity_pool_id = response.identity_pool_id;
    const user_pool_id = response.cognito_user_pool_id;
    localStorage.setItem('userPool', user_pool_id);
    localStorage.setItem('identity_pool_id', identity_pool_id);
    localStorage.setItem('client_id', response.client_id);

    const poolData = {
      UserPoolId: user_pool_id,
      IdentityPoolId: identity_pool_id,
      ClientId: response.client_id
    };

    AWS.config.update({ region: identity_pool_id.split(':')[0] });

    const userPool = new CognitoUserPool(poolData);
    const region = identity_pool_id.split(':')[0];
    localStorage.setItem('region', region);
    const authenticationData = {
      Username: response.username,
      Password: password
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: response.username,
      Pool: userPool
    };
    var sessionUserAttributes;
    const cognitoUser = new CognitoUser(userData);

    const authPromise = new Promise(async (resolve, reject) => {
      await cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result: any) {
          let primary_group = '';
          const idToken = result.getIdToken().getJwtToken();

          console.log('cognito payload', result.getIdToken().payload);
          const preferred_role = result.getIdToken().payload['cognito:preferred_role'];
          console.log('preferred_role', preferred_role);
          localStorage.setItem('preferred_role', preferred_role);
          localStorage.setItem('idToken', idToken);
          const accessToken = result.getAccessToken().getJwtToken();
          const refreshToken = result.getRefreshToken().getToken();
          const sub = result.getAccessToken().payload.sub;
          const userName = result.getAccessToken().payload.username;
          const exp = result.getIdToken().getExpiration(); // Get expiration time
          const cognito_idp = result.getIdToken().payload['cognito:idp'];
          console.log('cognito_idp', cognito_idp);

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: localStorage.getItem('identity_pool_id'), // Replace with your Identity Pool ID
            Logins: {
              'cognito-idp.us-east-1.amazonaws.com/us-east-1_11sbYdAmz': idToken // Use the ID token from authentication
            },
            RoleArn: preferred_role
          });

          (AWS.config.credentials as AWS.CognitoIdentityCredentials).get((err) => {
            if (err) {
              console.error('Error fetching credentials:', err);
              return;
            }
          });
          const websocket_url = `${response.websocketURL}/${process.env.REACT_APP_LANE}`;
          localStorage.setItem('webSocketUrl', websocket_url);
          const homeworkWebSocketUrl = `wss://${response.homeworkWebSocketURL}/${process.env.REACT_APP_LANE}`;
          localStorage.setItem('homeworkWebSocketUrl', homeworkWebSocketUrl);
          localStorage.setItem('token', idToken);
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('tokenExpiration', exp.toString());

          const groups = result.getIdToken().payload['cognito:groups'];

          try {
            primary_group = groups[0];
          } catch {
            primary_group = groups;
          }

          localStorage.setItem('primaryGroup', primary_group);
          localStorage.setItem('userId', sub);
          localStorage.setItem('userName', userName);
          const d = new Date();
          const newPasswordRequired = false;
          const loggedIn = true;
          localStorage.setItem('logintime', d.getTime().toString());
          resolve({
            idToken,
            accessToken,
            refreshToken,
            exp,
            newPasswordRequired,
            groups,
            primary_group,
            loggedIn,
            loginError: false,
            sub
          });
        },
        onFailure: function (err) {
          const errMessage = 'Invalid username or password';

          console.log('Authentication failed:', err);
          enqueueSnackbar('Invalid username or password', { variant: 'error' });

          localStorage.setItem('isFirstLogin', 'false');
          reject({
            loggedIn: false,
            loginError: true,
            errorMessage: errMessage
          });
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          const newPasswordRequired = true;
          const loggedIn = false;
          // store userAttributes on global variable
          sessionUserAttributes = userAttributes;
          resolve({ loggedIn, newPasswordRequired, sessionUserAttributes });
        }
      });
    });

    try {
      const authResult = yield authPromise;
      yield put(loginUserSuccess(authResult));
      yield call(setupExpirationTimer, authResult.exp);
    } catch (error: any) {
      yield put(loginUserError(error));
      localStorage.setItem('isFirstLogin', 'false');
    }
  } catch (error: any) {
    yield put(loginAccountError(error));
    alert('login account error message2');
    localStorage.setItem('isFirstLogin', 'false');
  }
}

function* setupExpirationTimer(expirationTime: number) {
  const currentTime = Math.floor(Date.now() / 1000);
  const timeUntilExpiration = expirationTime - currentTime;

  if (timeUntilExpiration > 0) {
    yield delay(timeUntilExpiration * 1000);
  }
  yield call(handleTokenExpiration);
}

function* handleTokenExpiration() {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      const newSession = yield call(refreshUserSession, refreshToken);
      if (newSession) {
        yield put(loginUserSuccess(newSession));
        yield call(setupExpirationTimer, newSession.exp);
      } else {
        throw new Error('Failed to refresh token');
      }
    } else {
      throw new Error('No refresh token available');
    }
  } catch (error) {
    console.error('Token refresh failed:', error);
    yield put(logoutThunk());
    localStorage.clear(); // Clear all localStorage items
    yield call([window.location, window.location.assign], '/login');
  }
}

async function refreshUserSession(refreshToken: string) {
  return new Promise((resolve, reject) => {
    const userPoolId = localStorage.getItem('userPool');
    const clientId = localStorage.getItem('client_id');

    if (!userPoolId || !clientId) {
      reject(new Error('User pool or client ID not found'));
      return;
    }

    const userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId
    });

    const userData = {
      Username: localStorage.getItem('username') || '',
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);
    const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });

    cognitoUser.refreshSession(cognitoRefreshToken, (err, session) => {
      if (err) {
        console.error('Error refreshing session:', err);
        reject(err);
      } else {
        const idToken = session.getIdToken().getJwtToken();
        const accessToken = session.getAccessToken().getJwtToken();
        const newRefreshToken = session.getRefreshToken().getToken();
        const exp = session.getIdToken().getExpiration();

        // Update localStorage
        localStorage.setItem('token', idToken);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        localStorage.setItem('tokenExpiration', exp.toString());

        resolve({
          idToken,
          accessToken,
          refreshToken: newRefreshToken,
          exp // Include any other necessary session information
        });
      }
    });
  });
}

export function* watchLoginUser() {
  yield takeLatest(LoginActionTypes.LOGIN_USER, loginUserSaga);
}
