import { takeLatest, put, call } from 'redux-saga/effects';
import { GradingRequestsActionTypes } from '../actions/getGradingRequestsActionTypes';
import {
  gradingRequestsSuccess,
  gradingRequestsError,
  submitGradingRequestsSuccess,
  submitGradingRequestsError,
  updateGradingRequestsSuccess,
  updateGradingRequestsError
} from '../actions/getGradingRequetsActions';
import { apiService } from '../../../services/api';

function* gradingRequestsRequestSaga(req: any) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getGradingRequests, req.payload);

    // Dispatch the success action with the API response
    yield put(gradingRequestsSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(gradingRequestsError(error.message));
  }
}

function* updateGradingRequestsRequestSaga(req: any) {
  try {
    const response = yield call(apiService.updateGradingRequest, req.payload);
    yield put(updateGradingRequestsSuccess(response));
  } catch (error: any) {
    yield put(updateGradingRequestsError(error.message));
  }
}

function* submitGradingRequestsRequestSaga(req: any) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.submitGradingRequest, req.payload);

    // Dispatch the success action with the API response
    yield put(submitGradingRequestsSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(submitGradingRequestsError(error.message));
  }
}

export function* watchGradingRequestsRequest() {
  yield takeLatest(GradingRequestsActionTypes.GRADING_REQUESTS, gradingRequestsRequestSaga);
  yield takeLatest(GradingRequestsActionTypes.SUBMIT_GRADING_REQUESTS, submitGradingRequestsRequestSaga);
  yield takeLatest(GradingRequestsActionTypes.UPDATE_GRADING_REQUESTS, updateGradingRequestsRequestSaga);
}
