import { takeLatest, put, call } from 'redux-saga/effects';
import { UpdateProfileActionTypes } from '../actions/updateProfileActionTypes';
import {
  updateProfileRequestSuccess,
  updateProfileRequestError,
  updateOtherProfileRequestSuccess,
  updateOtherProfileRequestError
} from '../actions/updateProfileActions';
import { apiService } from '../../../services/api';

function* updateProfileRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.updateProfile, req.payload);

    // Dispatch the success action with the API response
    yield put(updateProfileRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateProfileRequestError(error.message));
  }
}

function* updateOtherProfileRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.updateProfile, req.payload);

    // Dispatch the success action with the API response
    yield put(updateOtherProfileRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateOtherProfileRequestError(error.message));
  }
}

export function* watchUpdateProfileRequest() {
  yield takeLatest(UpdateProfileActionTypes.UPDATE_PROFILE_REQUEST, updateProfileRequestSaga);
  yield takeLatest(UpdateProfileActionTypes.UPDATE_OTHER_PROFILE_REQUEST, updateOtherProfileRequestSaga);
}
