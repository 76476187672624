import { ManageApplicantsActionTypes, ManageApplicantsAction } from '../actions/manageApplicantsActionTypes';

interface ManageApplicantsState {
  data: string[];
  getting: boolean;
  get_error: string | null;
  update_data: string[];
  updating: boolean;
  update_error: string | null;
  course_registrants: string[];
  getting_course_registrants: boolean;
  get_course_registrants_error: string | null;
  update_course_registrants: string[];
  updating_course_registrants: boolean;
  update_course_registrants_error: string | null;
}

const initialState: ManageApplicantsState = {
  data: [],
  getting: false,
  get_error: null,
  update_data: [],
  updating: false,
  update_error: null,
  course_registrants: [],
  getting_course_registrants: false,
  get_course_registrants_error: null,
  update_course_registrants: [],
  updating_course_registrants: false,
  update_course_registrants_error: null
};

const manageApplicantsReducer = (state = initialState, action: ManageApplicantsAction): ManageApplicantsState => {
  switch (action.type) {
    case ManageApplicantsActionTypes.GET_APPLICANTS:
      return { ...state, getting: true };
    case ManageApplicantsActionTypes.GET_APPLICANTS_SUCCESS:
      return { ...state, data: action.payload, getting: false, get_error: null };
    case ManageApplicantsActionTypes.GET_APPLICANTS_ERROR:
      return { ...state, getting: false, get_error: action.payload };
    case ManageApplicantsActionTypes.UPDATE_APPLICANTS:
      return { ...state, updating: true };
    case ManageApplicantsActionTypes.UPDATE_APPLICANTS_SUCCESS:
      return { ...state, update_data: action.payload, updating: false, update_error: null };
    case ManageApplicantsActionTypes.UPDATE_APPLICANTS_ERROR:
      return { ...state, updating: false, update_error: action.payload };
    case ManageApplicantsActionTypes.GET_COURSE_REGISTRANTS:
      return { ...state, getting_course_registrants: true };
    case ManageApplicantsActionTypes.GET_COURSE_REGISTRANTS_SUCCESS:
      return {
        ...state,
        course_registrants: action.payload,
        getting_course_registrants: false,
        get_course_registrants_error: null
      };
    case ManageApplicantsActionTypes.GET_COURSE_REGISTRANTS_ERROR:
      return { ...state, getting_course_registrants: false, get_course_registrants_error: action.payload };
    case ManageApplicantsActionTypes.UPDATE_COURSE_REGISTRANTS:
      return { ...state, updating_course_registrants: true };
    case ManageApplicantsActionTypes.UPDATE_COURSE_REGISTRANTS_SUCCESS:
      return {
        ...state,
        update_course_registrants: action.payload,
        updating_course_registrants: false,
        update_course_registrants_error: null
      };
    case ManageApplicantsActionTypes.UPDATE_COURSE_REGISTRANTS_ERROR:
      return { ...state, updating_course_registrants: false, update_course_registrants_error: action.payload };
    default:
      return state;
  }
};

export default manageApplicantsReducer;
