export enum ManageApplicantsActionTypes {
  GET_APPLICANTS = 'manageApplicants/GET_APPLICANTS',
  GET_APPLICANTS_SUCCESS = 'manageApplicants/GET_APPLICANTS_SUCCESS',
  GET_APPLICANTS_ERROR = 'manageApplicants/GET_APPLICANTS_ERROR',
  UPDATE_APPLICANTS = 'manageApplicants/UPDATE_APPLICANTS',
  UPDATE_APPLICANTS_SUCCESS = 'manageApplicants/UPDATE_APPLICANTS_SUCCESS',
  UPDATE_APPLICANTS_ERROR = 'manageApplicants/UPDATE_APPLICANTS_ERROR',
  GET_COURSE_REGISTRANTS = 'manageApplicants/GET_COURSE_REGISTRANTS',
  GET_COURSE_REGISTRANTS_SUCCESS = 'manageApplicants/GET_COURSE_REGISTRANTS_SUCCESS',
  GET_COURSE_REGISTRANTS_ERROR = 'manageApplicants/GET_COURSE_REGISTRANTS_ERROR',
  UPDATE_COURSE_REGISTRANTS = 'manageApplicants/UPDATE_COURSE_REGISTRANTS',
  UPDATE_COURSE_REGISTRANTS_SUCCESS = 'manageApplicants/UPDATE_COURSE_REGISTRANTS_SUCCESS',
  UPDATE_COURSE_REGISTRANTS_ERROR = 'manageApplicants/UPDATE_COURSE_REGISTRANTS_ERROR'
}

export interface ManageApplicantsAction {
  type: ManageApplicantsActionTypes;
  payload?: any;
}
