import { TutorsActionTypes, TutorsAction } from '../actions/getTutorActionTypes';

interface TutorsState {
  data: string[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
  submitted_data: string[];
  submitting: boolean;
  submitted: boolean;
  submit_error: string | null;
  tutors: any[];
  tutors_error: string | null;
  tutors_loading: boolean;
  tutors_loaded: boolean;
}

const initialState: TutorsState = {
  data: [],
  loading: false,
  loaded: false,
  error: null,
  submitted_data: [],
  submitting: false,
  submitted: false,
  submit_error: null,
  tutors: [],
  tutors_error: null,
  tutors_loading: false,
  tutors_loaded: false
};

const tutorsReducer = (state = initialState, action: TutorsAction): TutorsState => {
  switch (action.type) {
    case TutorsActionTypes.TUTORS_REQUEST:
      return { ...state, loading: true };
    case TutorsActionTypes.TUTORS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, loaded: true, error: null };
    case TutorsActionTypes.TUTORS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case TutorsActionTypes.SUBMIT_TUTORS_REQUEST:
      return { ...state, submitting: true };
    case TutorsActionTypes.SUBMIT_TUTORS_REQUEST_SUCCESS:
      return { ...state, submitted_data: action.payload, submitting: false, submitted: true, submit_error: null };
    case TutorsActionTypes.SUBMIT_TUTORS_REQUEST_ERROR:
      return { ...state, submitting: false, submit_error: action.payload };
    case TutorsActionTypes.TUTORS_ALL_REQUEST:
      return { ...state, tutors_loading: true };
    case TutorsActionTypes.TUTORS_ALL_REQUEST_SUCCESS:
      return { ...state, tutors: action.payload, tutors_loading: false, tutors_loaded: true, tutors_error: null };
    case TutorsActionTypes.TUTORS_ALL_REQUEST_ERROR:
      return { ...state, tutors_loading: false, tutors_error: action.payload };
    default:
      return state;
  }
};

export default tutorsReducer;
