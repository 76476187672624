// features/createClass/actions/createClassActions.ts
import { createHomeworkActionTypes, createHomeworkAction } from './createHomeworkTypes';

export const createHomeworkRequest = (data: any): createHomeworkAction => ({
  type: createHomeworkActionTypes.CREATE_HOMEWORK,
  payload: data
});

export const createHomeworkRequestSuccess = (data: any): createHomeworkAction => ({
  type: createHomeworkActionTypes.CREATE_HOMEWORK_SUCCESS,
  payload: data
});

export const createHomeworkRequestError = (error: string): createHomeworkAction => ({
  type: createHomeworkActionTypes.CREATE_HOMEWORK_ERROR,
  payload: error
});

export const getSampleHomeworksRequest = (data: any): createHomeworkAction => ({
  type: createHomeworkActionTypes.GET_SAMPLE_HOMEWORKS,
  payload: data
});

export const getSampleHomeworksRequestSuccess = (data: any): createHomeworkAction => ({
  type: createHomeworkActionTypes.GET_SAMPLE_HOMEWORKS_SUCCESS,
  payload: data
});

export const getSampleHomeworksRequestError = (error: string): createHomeworkAction => ({
  type: createHomeworkActionTypes.GET_SAMPLE_HOMEWORKS_ERROR,
  payload: error
});

export const getHomeworkCategoriesRequest = (data: any): createHomeworkAction => ({
  type: createHomeworkActionTypes.GET_HOMEWORK_CATEGORIES,
  payload: data
});

export const getHomeworkCategoriesRequestSuccess = (data: any): createHomeworkAction => ({
  type: createHomeworkActionTypes.GET_HOMEWORK_CATEGORIES_SUCCESS,
  payload: data
});

export const getHomeworkCategoriesRequestError = (error: string): createHomeworkAction => ({
  type: createHomeworkActionTypes.GET_HOMEWORK_CATEGORIES_ERROR,
  payload: error
});

export const assignHomeworkToStudentRequest = (data: any): createHomeworkAction => ({
  type: createHomeworkActionTypes.ASSIGN_HOMEWORK_TO_STUDENT,
  payload: data
});

export const assignHomeworkToStudentRequestSuccess = (data: any): createHomeworkAction => ({
  type: createHomeworkActionTypes.ASSIGN_HOMEWORK_TO_STUDENT_SUCCESS,
  payload: data
});

export const assignHomeworkToStudentRequestError = (error: string): createHomeworkAction => ({
  type: createHomeworkActionTypes.ASSIGN_HOMEWORK_TO_STUDENT_ERROR,
  payload: error
});
