import { createHomeworkActionTypes, createHomeworkAction } from '../actions/createHomeworkTypes';

interface createHomeworkState {
  data: string[];
  loading: boolean;
  load_error: string | null;
  sample_homeworks: string[];
  sample_homeworks_loading: boolean;
  sample_homeworks_load_error: string | null;
  homework_categories: string[];
  homework_categories_loading: boolean;
  homework_categories_load_error: string | null;
  assign_homework_to_student_loading: boolean;
  assign_homework_to_student_load_error: string | null;
}

const initialState: createHomeworkState = {
  data: [],
  loading: false,
  load_error: null,
  sample_homeworks: [],
  sample_homeworks_loading: false,
  sample_homeworks_load_error: null,
  homework_categories: [],
  homework_categories_loading: false,
  homework_categories_load_error: null,
  assign_homework_to_student_loading: false,
  assign_homework_to_student_load_error: null
};

const createHomeworkReducer = (state = initialState, action: createHomeworkAction): createHomeworkState => {
  switch (action.type) {
    case createHomeworkActionTypes.CREATE_HOMEWORK:
      return { ...state, loading: true };
    case createHomeworkActionTypes.CREATE_HOMEWORK_SUCCESS:
      return { ...state, data: action.payload, loading: false, load_error: null };
    case createHomeworkActionTypes.CREATE_HOMEWORK_ERROR:
      return { ...state, loading: false, load_error: action.payload };
    case createHomeworkActionTypes.GET_SAMPLE_HOMEWORKS:
      return { ...state, sample_homeworks_loading: true };
    case createHomeworkActionTypes.GET_SAMPLE_HOMEWORKS_SUCCESS:
      return {
        ...state,
        sample_homeworks: action.payload,
        sample_homeworks_loading: false,
        sample_homeworks_load_error: null
      };
    case createHomeworkActionTypes.GET_SAMPLE_HOMEWORKS_ERROR:
      return { ...state, sample_homeworks_loading: false, sample_homeworks_load_error: action.payload };
    case createHomeworkActionTypes.GET_HOMEWORK_CATEGORIES:
      return { ...state, homework_categories_loading: true };
    case createHomeworkActionTypes.GET_HOMEWORK_CATEGORIES_SUCCESS:
      return {
        ...state,
        homework_categories: action.payload,
        homework_categories_loading: false,
        homework_categories_load_error: null
      };
    case createHomeworkActionTypes.GET_HOMEWORK_CATEGORIES_ERROR:
      return { ...state, homework_categories_loading: false, homework_categories_load_error: action.payload };
    case createHomeworkActionTypes.ASSIGN_HOMEWORK_TO_STUDENT:
      return { ...state, assign_homework_to_student_loading: true };
    case createHomeworkActionTypes.ASSIGN_HOMEWORK_TO_STUDENT_SUCCESS:
      return { ...state, assign_homework_to_student_loading: false, assign_homework_to_student_load_error: null };
    case createHomeworkActionTypes.ASSIGN_HOMEWORK_TO_STUDENT_ERROR:
      return {
        ...state,
        assign_homework_to_student_loading: false,
        assign_homework_to_student_load_error: action.payload
      };
    default:
      return state;
  }
};

export default createHomeworkReducer;
