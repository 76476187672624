import { GradingRequestsActionTypes, GradingRequestsAction } from './getGradingRequestsActionTypes';

export const gradingRequests = (data: any): GradingRequestsAction => ({
  type: GradingRequestsActionTypes.GRADING_REQUESTS,
  payload: data
});

export const gradingRequestsSuccess = (data: any): GradingRequestsAction => ({
  type: GradingRequestsActionTypes.GRADING_REQUESTS_SUCCESS,
  payload: data
});

export const gradingRequestsError = (error: string): GradingRequestsAction => ({
  type: GradingRequestsActionTypes.GRADING_REQUESTS_ERROR,
  payload: error
});

export const updateGradingRequests = (data: any): GradingRequestsAction => ({
  type: GradingRequestsActionTypes.UPDATE_GRADING_REQUESTS,
  payload: data
});

export const updateGradingRequestsSuccess = (data: any): GradingRequestsAction => ({
  type: GradingRequestsActionTypes.UPDATE_GRADING_REQUESTS_SUCCESS,
  payload: data
});

export const updateGradingRequestsError = (error: string): GradingRequestsAction => ({
  type: GradingRequestsActionTypes.UPDATE_GRADING_REQUESTS_ERROR,
  payload: error
});

export const submitGradingRequests = (data: any): GradingRequestsAction => ({
  type: GradingRequestsActionTypes.SUBMIT_GRADING_REQUESTS,
  payload: data
});

export const submitGradingRequestsSuccess = (data: any): GradingRequestsAction => ({
  type: GradingRequestsActionTypes.SUBMIT_GRADING_REQUESTS_SUCCESS,
  payload: data
});

export const submitGradingRequestsError = (error: string): GradingRequestsAction => ({
  type: GradingRequestsActionTypes.SUBMIT_GRADING_REQUESTS_ERROR,
  payload: error
});
