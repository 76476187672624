export enum HomeworkActionTypes {
  SELECT_HOMEWORK = 'homeworks/SELECT_HOMEWORK',
  REQUEST_HOMEWORK_PDF = 'homeworks/REQUEST_HOMEWORK_PDF',
  REQUEST_HOMEWORK_PDF_SUCCESS = 'homeworks/REQUEST_HOMEWORK_PDF_SUCCESS',
  REQUEST_HOMEWORK_PDF_ERROR = 'homeworks/REQUEST_HOMEWORK_PDF_ERROR',
  SET_HOMEWORK_LIST = 'homeworks/SET_HOMEWORK_LIST',
  SET_HOMEWORK_LIST_SUCCESS = 'homeworks/SET_HOMEWORK_LIST_SUCCESS',
  SET_HOMEWORK_LIST_ERROR = 'homeworks/SET_HOMEWORK_LIST_ERROR',
  SET_CONNECTION_ID = 'homeworks/SET_CONNECTION_ID',
  SET_CONNECTION_ID_SUCCESS = 'homeworks/SET_CONNECTION_ID_SUCCESS',
  SET_CONNECTION_ID_ERROR = 'homeworks/SET_CONNECTION_ID_ERROR',
  SET_PAGE_DETAILS = 'homeworks/SET_PAGE_DETAILS',
  SET_PAGE_DETAILS_SUCCESS = 'homeworks/SET_PAGE_DETAILS_SUCCESS',
  SET_PAGE_DETAILS_ERROR = 'homeworks/SET_PAGE_DETAILS_ERROR',
  SET_PAGE_QUESTIONS = 'homeworks/SET_PAGE_QUESTIONS',
  SET_PAGE_QUESTIONS_SUCCESS = 'homeworks/SET_PAGE_QUESTIONS_SUCCESS',
  SET_PAGE_QUESTIONS_ERROR = 'homeworks/SET_PAGE_QUESTIONS_ERROR',
  UPDATE_HOMEWORK_QUESTION = 'homeworks/UPDATE_HOMEWORK_QUESTION',
  UPDATE_HOMEWORK_QUESTION_SUCCESS = 'homeworks/UPDATE_HOMEWORK_QUESTION_SUCCESS',
  UPDATE_HOMEWORK_QUESTION_ERROR = 'homeworks/UPDATE_HOMEWORK_QUESTION_ERROR'
}

export interface HomeworkAction {
  type: HomeworkActionTypes;
  payload?: any;
}
