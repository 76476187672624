export enum UpdateProfileActionTypes {
  UPDATE_PROFILE_REQUEST = 'updateProfile/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_REQUEST_SUCCESS = 'updateProfile/UPDATE_PROFILE_REQUEST_SUCCESS',
  UPDATE_PROFILE_REQUEST_ERROR = 'updateProfile/UPDATE_PROFILE_REQUEST_ERROR',
  UPDATE_OTHER_PROFILE_REQUEST = 'updateProfile/UPDATE_OTHER_PROFILE_REQUEST',
  UPDATE_OTHER_PROFILE_REQUEST_SUCCESS = 'updateProfile/UPDATE_OTHER_PROFILE_REQUEST_SUCCESS',
  UPDATE_OTHER_PROFILE_REQUEST_ERROR = 'updateProfile/UPDATE_OTHER_PROFILE_REQUEST_ERROR'
}

export interface UpdateProfileAction {
  type: UpdateProfileActionTypes;
  payload?: any;
}
