import { GradingRequestsActionTypes, GradingRequestsAction } from '../actions/getGradingRequestsActionTypes';

interface GradingRequestsState {
  gradingRequests: string[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
  requesting: boolean;
  requested: boolean;
  request_error: string | null;
  updating: boolean;
  updated: boolean;
  update_error: string | null;
  submittedData: string[];
}

const initialState: GradingRequestsState = {
  gradingRequests: [],
  loading: false,
  loaded: false,
  requesting: false,
  requested: false,
  request_error: null,
  submittedData: [],
  error: null,
  updating: false,
  updated: false,
  update_error: null
};

const gradingRequestsReducer = (state = initialState, action: GradingRequestsAction): GradingRequestsState => {
  switch (action.type) {
    case GradingRequestsActionTypes.GRADING_REQUESTS:
      return { ...state, loading: true };
    case GradingRequestsActionTypes.GRADING_REQUESTS_SUCCESS:
      return { ...state, gradingRequests: action.payload, loading: false, loaded: true, error: null };
    case GradingRequestsActionTypes.GRADING_REQUESTS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case GradingRequestsActionTypes.SUBMIT_GRADING_REQUESTS:
      return { ...state, requesting: true };
    case GradingRequestsActionTypes.SUBMIT_GRADING_REQUESTS_SUCCESS:
      return { ...state, submittedData: action.payload, requesting: false, requested: true, request_error: null };
    case GradingRequestsActionTypes.SUBMIT_GRADING_REQUESTS_ERROR:
      return { ...state, requesting: false, request_error: action.payload };
    case GradingRequestsActionTypes.UPDATE_GRADING_REQUESTS:
      return { ...state, updating: true };
    case GradingRequestsActionTypes.UPDATE_GRADING_REQUESTS_SUCCESS:
      return { ...state, updating: false, updated: true, update_error: null };
    case GradingRequestsActionTypes.UPDATE_GRADING_REQUESTS_ERROR:
      return { ...state, updating: false, update_error: action.payload };
    default:
      return state;
  }
};

export default gradingRequestsReducer;
