import { UpdateProfileActionTypes, UpdateProfileAction } from '../actions/updateProfileActionTypes';

interface UpdateProfileState {
  data: string[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
  otherProfileData: string[];
  otherProfileLoading: boolean;
  otherProfileLoaded: boolean;
  otherProfileError: string | null;
}

const initialState: UpdateProfileState = {
  data: [],
  loading: false,
  loaded: false,
  error: null,
  otherProfileData: [],
  otherProfileLoading: false,
  otherProfileLoaded: false,
  otherProfileError: null
};

const updateProfileReducer = (state = initialState, action: UpdateProfileAction): UpdateProfileState => {
  switch (action.type) {
    case UpdateProfileActionTypes.UPDATE_PROFILE_REQUEST:
      return { ...state, loading: true };
    case UpdateProfileActionTypes.UPDATE_PROFILE_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, loaded: true, error: null };
    case UpdateProfileActionTypes.UPDATE_PROFILE_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case UpdateProfileActionTypes.UPDATE_OTHER_PROFILE_REQUEST:
      return { ...state, otherProfileLoading: true };
    case UpdateProfileActionTypes.UPDATE_OTHER_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        otherProfileData: action.payload,
        otherProfileLoading: false,
        otherProfileLoaded: true,
        otherProfileError: null
      };
    case UpdateProfileActionTypes.UPDATE_OTHER_PROFILE_REQUEST_ERROR:
      return { ...state, otherProfileLoading: false, error: action.payload };
    default:
      return state;
  }
};

export default updateProfileReducer;
