import { takeLatest, put, call } from 'redux-saga/effects';
import { makePaymentActionTypes } from '../actions/makePaymentActionTypes';
import {
  makePaymentSuccess,
  makePaymentError,
  createPaymentIntentSuccess,
  createPaymentIntentError,
  recordPaymentSuccess,
  recordPaymentError
} from '../actions/makePaymentActions';
import { apiService } from '../../../services/api';

function* makePaymentSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.MakePayment, req.payload);
    // Dispatch the success action with the API response
    yield put(makePaymentSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(makePaymentError(error.message));
  }
}

function* recordPaymentSaga(req) {
  try {
    const response = yield call(apiService.MakePayment, req.payload);
    yield put(recordPaymentSuccess(response));
  } catch (error: any) {
    yield put(recordPaymentError(error.message));
  }
}

function* createPaymentIntentSaga(req) {
  try {
    const response = yield call(apiService.MakePayment, req.payload);
    yield put(createPaymentIntentSuccess(response));
  } catch (error: any) {
    yield put(createPaymentIntentError(error.message));
  }
}

export function* watchmakePaymentRequest() {
  yield takeLatest(makePaymentActionTypes.MAKE_PAYMENT, makePaymentSaga);
  yield takeLatest(makePaymentActionTypes.CREATE_PAYMENT_INTENT, createPaymentIntentSaga);
  yield takeLatest(makePaymentActionTypes.RECORD_PAYMENT, recordPaymentSaga);
}
