import { makePaymentActionTypes, makePaymentAction } from './makePaymentActionTypes';

export const createPaymentIntent = (data: any): makePaymentAction => ({
  type: makePaymentActionTypes.CREATE_PAYMENT_INTENT,
  payload: data
});

export const createPaymentIntentSuccess = (data: any): makePaymentAction => ({
  type: makePaymentActionTypes.CREATE_PAYMENT_INTENT_SUCCESS,
  payload: data
});

export const createPaymentIntentError = (error: string): makePaymentAction => ({
  type: makePaymentActionTypes.CREATE_PAYMENT_INTENT_ERROR,
  payload: error
});

export const makePayment = (data: any): makePaymentAction => ({
  type: makePaymentActionTypes.MAKE_PAYMENT,
  payload: data
});

export const makePaymentSuccess = (data: any): makePaymentAction => ({
  type: makePaymentActionTypes.MAKE_PAYMENT_SUCCESS,
  payload: data
});

export const makePaymentError = (error: string): makePaymentAction => ({
  type: makePaymentActionTypes.MAKE_PAYMENT_ERROR
});

export const recordPayment = (data: any): makePaymentAction => ({
  type: makePaymentActionTypes.RECORD_PAYMENT,
  payload: data
});

export const recordPaymentSuccess = (data: any): makePaymentAction => ({
  type: makePaymentActionTypes.RECORD_PAYMENT_SUCCESS,
  payload: data
});

export const recordPaymentError = (error: string): makePaymentAction => ({
  type: makePaymentActionTypes.RECORD_PAYMENT_ERROR,
  payload: error
});
