import { makePaymentActionTypes, makePaymentAction } from '../actions/makePaymentActionTypes';

interface makePaymentState {
  data: string | null;
  account: string | null;
  loading: boolean;
  error: string | null;
  record_payment_loading: boolean;
  record_payment_error: string | null;
}

const initialState: makePaymentState = {
  data: null,
  account: null,
  loading: false,
  error: null,
  record_payment_loading: false,
  record_payment_error: null
};

const makePaymentReducer = (state = initialState, action: makePaymentAction): makePaymentState => {
  switch (action.type) {
    case makePaymentActionTypes.CREATE_PAYMENT_INTENT:
      return { ...state, loading: true };
    case makePaymentActionTypes.CREATE_PAYMENT_INTENT_SUCCESS:
      return { ...state, account: action.payload, loading: false, error: null };
    case makePaymentActionTypes.CREATE_PAYMENT_INTENT_ERROR:
      return { ...state, loading: false, error: action.payload };
    case makePaymentActionTypes.MAKE_PAYMENT:
      return { ...state, loading: true };
    case makePaymentActionTypes.MAKE_PAYMENT_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case makePaymentActionTypes.MAKE_PAYMENT_ERROR:
      return { ...state, loading: false, error: action.payload };
    case makePaymentActionTypes.RECORD_PAYMENT:
      return { ...state, record_payment_loading: true };
    case makePaymentActionTypes.RECORD_PAYMENT_SUCCESS:
      return { ...state, record_payment_loading: false, record_payment_error: null };
    case makePaymentActionTypes.RECORD_PAYMENT_ERROR:
      return { ...state, record_payment_loading: false, record_payment_error: action.payload };
    default:
      return state;
  }
};

export default makePaymentReducer;
