export enum GradingRequestsActionTypes {
  GRADING_REQUESTS = 'getGradingRequests/GRADING_REQUESTS',
  GRADING_REQUESTS_SUCCESS = 'getGradingRequests/GRADING_REQUESTS_SUCCESS',
  GRADING_REQUESTS_ERROR = 'getGradingRequests/GRADING_REQUESTS_ERROR',
  UPDATE_GRADING_REQUESTS = 'getGradingRequests/UPDATE_GRADING_REQUESTS',
  UPDATE_GRADING_REQUESTS_SUCCESS = 'getGradingRequests/UPDATE_GRADING_REQUESTS_SUCCESS',
  UPDATE_GRADING_REQUESTS_ERROR = 'getGradingRequests/UPDATE_GRADING_REQUESTS_ERROR',
  SUBMIT_GRADING_REQUESTS = 'getGradingRequests/SUBMIT_GRADING_REQUESTS',
  SUBMIT_GRADING_REQUESTS_SUCCESS = 'getGradingRequests/SUBMIT_GRADING_REQUESTS_SUCCESS',
  SUBMIT_GRADING_REQUESTS_ERROR = 'getGradingRequests/SUBMIT_GRADING_REQUESTS_ERROR'
}

export interface GradingRequestsAction {
  type: GradingRequestsActionTypes;
  payload?: any;
}
