import { takeLatest, put, call } from 'redux-saga/effects';
import { createHomeworkActionTypes } from '../actions/createHomeworkTypes';
import {
  createHomeworkRequestSuccess,
  createHomeworkRequestError,
  getSampleHomeworksRequestSuccess,
  getSampleHomeworksRequestError,
  getHomeworkCategoriesRequestSuccess,
  getHomeworkCategoriesRequestError,
  assignHomeworkToStudentRequestSuccess,
  assignHomeworkToStudentRequestError
} from '../actions/createHomeworkActions';
import { apiService } from '../../../services/api';

function* createHomeworkRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.createHomework, req.payload);

    // Dispatch the success action with the API response
    yield put(createHomeworkRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(createHomeworkRequestError(error.message));
  }
}

function* getSampleHomeworksRequestSaga(req) {
  try {
    const response = yield call(apiService.createHomework, req.payload);
    yield put(getSampleHomeworksRequestSuccess(response));
  } catch (error: any) {
    yield put(getSampleHomeworksRequestError(error.message));
  }
}

function* getHomeworkCategoriesRequestSaga(req) {
  try {
    const response = yield call(apiService.createHomework, req.payload);
    yield put(getHomeworkCategoriesRequestSuccess(response));
  } catch (error: any) {
    yield put(getHomeworkCategoriesRequestError(error.message));
  }
}

function* assignHomeworkToStudentRequestSaga(req) {
  try {
    const response = yield call(apiService.createHomework, req.payload);
    yield put(assignHomeworkToStudentRequestSuccess(response));
  } catch (error: any) {
    yield put(assignHomeworkToStudentRequestError(error.message));
  }
}

export function* watchCreateHomeworkRequest() {
  yield takeLatest(createHomeworkActionTypes.CREATE_HOMEWORK, createHomeworkRequestSaga);
  yield takeLatest(createHomeworkActionTypes.GET_SAMPLE_HOMEWORKS, getSampleHomeworksRequestSaga);
  yield takeLatest(createHomeworkActionTypes.GET_HOMEWORK_CATEGORIES, getHomeworkCategoriesRequestSaga);
  yield takeLatest(createHomeworkActionTypes.ASSIGN_HOMEWORK_TO_STUDENT, assignHomeworkToStudentRequestSaga);
}
