// actions/homeworkActions.ts

import { HomeworkActionTypes } from './homeworkActionTypes';

export const selectHomework = (selectedHomework: any) => ({
  type: HomeworkActionTypes.SELECT_HOMEWORK,
  payload: selectedHomework
});

export const requestHomeworkPDF = (homeworkPDF: any) => ({
  type: HomeworkActionTypes.REQUEST_HOMEWORK_PDF,
  payload: homeworkPDF
});

export const requestHomeworkPDFSuccess = (homeworkPDF: any) => ({
  type: HomeworkActionTypes.REQUEST_HOMEWORK_PDF_SUCCESS,
  payload: homeworkPDF
});

export const requestHomeworkPDFError = (error: any) => ({
  type: HomeworkActionTypes.REQUEST_HOMEWORK_PDF_ERROR,
  payload: error
});

export const setHomeworkList = (homeworkList: any) => ({
  type: HomeworkActionTypes.SET_HOMEWORK_LIST,
  payload: homeworkList
});

export const setHomeworkListSuccess = (homeworkList: any) => ({
  type: HomeworkActionTypes.SET_HOMEWORK_LIST_SUCCESS,
  payload: homeworkList
});

export const setHomeworkListError = (error: any) => ({
  type: HomeworkActionTypes.SET_HOMEWORK_LIST_ERROR,
  payload: error
});

export const setConnectionId = (connectionId: string) => ({
  type: HomeworkActionTypes.SET_CONNECTION_ID,
  payload: connectionId
});

export const setConnectionIdSuccess = (connectionId: string) => ({
  type: HomeworkActionTypes.SET_CONNECTION_ID_SUCCESS,
  payload: connectionId
});

export const setConnectionIdError = (error: any) => ({
  type: HomeworkActionTypes.SET_CONNECTION_ID_ERROR,
  payload: error
});

export const setPageQuestions = (pageQuestions: any) => ({
  type: HomeworkActionTypes.SET_PAGE_QUESTIONS,
  payload: pageQuestions
});

export const setPageQuestionsSuccess = (pageQuestions: any) => ({
  type: HomeworkActionTypes.SET_PAGE_QUESTIONS_SUCCESS,
  payload: pageQuestions
});

export const setPageQuestionsError = (error: any) => ({
  type: HomeworkActionTypes.SET_PAGE_QUESTIONS_ERROR,
  payload: error
});

export const setPageDetails = (pageDetails: any) => ({
  type: HomeworkActionTypes.SET_PAGE_DETAILS,
  payload: pageDetails
});

export const setPageDetailsSuccess = (pageDetails: any) => ({
  type: HomeworkActionTypes.SET_PAGE_DETAILS_SUCCESS,
  payload: pageDetails
});

export const setPageDetailsError = (error: any) => ({
  type: HomeworkActionTypes.SET_PAGE_DETAILS_ERROR,
  payload: error
});

export const updateHomeworkQuestion = (homeworkQuestion: any) => ({
  type: HomeworkActionTypes.UPDATE_HOMEWORK_QUESTION,
  payload: homeworkQuestion
});

export const updateHomeworkQuestionSuccess = (homeworkQuestion: any) => ({
  type: HomeworkActionTypes.UPDATE_HOMEWORK_QUESTION_SUCCESS,
  payload: homeworkQuestion
});

export const updateHomeworkQuestionError = (error: any) => ({
  type: HomeworkActionTypes.UPDATE_HOMEWORK_QUESTION_ERROR,
  payload: error
});
